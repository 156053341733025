<template>
  <div class="coinresult">
    <p>"코인입금이 정상처리되었습니다. 계속해서 캐시입금을 진행해주세요.
코인입금 신청하신 금액과 동일한 금액을 충전신청 해 주시면됩니다."</p>
    <a @click="closeWindow">확인</a>
  </div>
</template>

<script>

export default {
  name: 'coinresult',
  data () {
  },
  methods: {
    closeWindow () {
      window.open('', '_self', '')
      window.close()
    }
  }
}
</script>

<style scoped>
.coinresult {display: flex;flex-direction: column;justify-content: center;align-items: center;gap: 100px;padding: 100px 0;}
.coinresult p {font-size: 15pt;font-weight: bold;color: #2f3545;line-height: 1.5em;margin-top: 100px;padding: 0 20px;}
.coinresult a {background: linear-gradient( to bottom, #14257a, #177bba);border-radius: 10px;width: 193px;height: 48px;color: #fff;font-weight: bold;display: flex;align-items: center;justify-content: center;}
</style>
<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
